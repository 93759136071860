function getColorForString(str) {
  const colors = [
    "#FFEB3B", // Light Yellow
    "#FFC107", // Amber
    "#FF9800", // Orange
    "#FFCDD2", // Light Pink
    "#C8E6C9", // Light Green
    "#B3E5FC", // Light Blue
    "#D1C4E9", // Lavender
    "#FFE0B2", // Peach
    "#F8BBD0", // Pink Blush
    "#FFF9C4", // Pale Yellow
  ];

  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const index = Math.abs(hash) % colors.length;
  return colors[index];
}

export { getColorForString };
