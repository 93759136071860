import { Button, Dropdown, Input, Popconfirm } from "antd";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../../../App";
import ChooseLanguage from "../../../Components/Common/ChooseLanguage";
import Logo from "../../../Assets/Images/betterLady.png";
import {
  handleLogout,
  handleUpdateUser,
} from "../../../Utils/UpdateUsersState";
import {
  DeleteOutlined,
  EditOutlined,
  LogoutOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import "./style.css";
import { IoIosArrowUp } from "react-icons/io";
import { FaArrowLeft } from "react-icons/fa";
import Footer from "../../../Components/Common/Footer";
import Level from "../../../Components/Common/Level";
import {
  addComment,
  commentUrl,
  deleteComment,
  deleteForum,
  forumPost,
  forumPostComments,
  upvote,
  upvoteComment,
} from "../../../API/forum";
import { getColorForString } from "../../../Utils/Helper/levelColor";
import { ClipLoader } from "react-spinners";
import { calculateTimeAgo } from "../../../Utils/Helper/timeDifference";
import { SlOptionsVertical } from "react-icons/sl";
import NewDiscussionModal from "../../../Components/Common/Forum Button/NewDiscussionModal";
import Notifications from "../../../Components/Common/Notifications";

const ForumPost = () => {
  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(false);
  const [loadingCommentPost, setLoadingCommentPost] = useState(false);
  const [newComment, setNewComment] = useState(null);
  const [postData, setPostData] = useState(null);
  const [postComments, setPostComments] = useState();
  const [commentsNextPageUrl, setCommentsNextPageUrl] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editData, setEditData] = useState(false);
  const [isEditDescriptionModalOpen, setIsEditDescriptionModalOpen] =
    useState(false);
  const [editDescriptionData, setEditDescriptionData] = useState(false);
  const { userData, setUserData, setUserToken } = useContext(UserContext);
  const navigate = useNavigate();
  const params = useParams();
  const { t } = useTranslation();
  const items = [
    {
      key: "1",
      label: "Settings",
      icon: <SettingOutlined />,
      onClick: () => {
        handleUpdateUser(setUserData, { ...userData, account: true });
      },
    },
    {
      key: "2",
      label: "Logout",
      icon: <LogoutOutlined />,
      onClick: () => handleLogout(setUserData, setUserToken),
    },
  ];
  const getInitials = useCallback(() => {
    if (userData?.name) {
      return userData.name
        .split(" ")
        .map((word) => word[0].toUpperCase())
        .join("");
    }
  }, [userData?.name]);
  useEffect(() => {
    getInitials();
  }, [getInitials]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const res = await forumPost({ forum_id: params?.id });
      const res2 = await forumPostComments({ forum_id: params?.id });
      if (Math.floor(res?.status / 100 === 2)) {
        setPostComments(res2?.data?.results);
        setCommentsNextPageUrl(res2?.data?.next);
        setPostData(res?.data);
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  const handleUpvoteComment = async (data) => {
    const res = await upvoteComment({
      comment_id: data?.comment_id,
      vote: !data?.user_vote,
    });
    const list = postComments.map((item) => {
      return item?.comment_id === res?.data?.comment_id
        ? { ...item, upvotes: res?.data?.upvotes, user_vote: res?.data?.vote }
        : item;
    });
    setPostComments(list);
  };

  const fetchNextPageComments = async () => {
    setLoadingData(true);
    const res = await commentUrl(commentsNextPageUrl);
    if (Math.floor(res?.status / 100) === 2) {
      setPostComments([...postComments, ...res?.data?.results]);
      setCommentsNextPageUrl(res?.data?.next);
    } else {
      // api["error"]({
      //   message: t("fetchingFailed"),
      //   description: res?.response?.data?.detail,
      // });
    }
    setLoadingData(false);
  };

  const handleAddComment = async () => {
    setLoadingCommentPost(true);
    const res = await addComment({ forum_id: params?.id, text: newComment });
    if (Math.floor(res?.status / 100) === 2) {
      setNewComment("");
      const res2 = await forumPostComments({ forum_id: params?.id });
      setPostComments(res2?.data?.results);
      setCommentsNextPageUrl(res2?.data?.next);
      setLoadingCommentPost(false);
    }
  };

  const handleUpvote = async (data) => {
    // try {
    // } catch (e) {
    //   console.log(e);
    // }
    const res = await upvote({
      forum_id: params.id,
      vote: !postData?.my_vote,
    });
    setPostData({
      ...postData,
      votes: res?.data?.upvotes,
      my_vote: res?.data?.vote,
    });
  };

  const handleDeleteComment = async (id) => {
    const res = await deleteComment(id);
    if (Math.floor(res?.status / 100) === 2) {
      setLoadingCommentPost(true);
      const res2 = await forumPostComments({ forum_id: params?.id });
      setPostComments(res2?.data?.results);
      setCommentsNextPageUrl(res2?.data?.next);
      setLoadingCommentPost(false);
    }
  };

  const handleUpdatePost = async (data) => {
    setIsEditModalOpen(true);
    setEditData({
      category: postData?.category,
      topic: postData?.forum_topic,
      forum_id: params?.id,
    });
  };

  const handleUpdatePostDescription = async () => {
    setIsEditDescriptionModalOpen(true);
    setEditDescriptionData({
      comment: postData?.comment,
      comment_id: postData?.comment_id,
      forum_id: postData?.forum_id,
    });
  };

  const handleDeletePost = async () => {
    const res = await deleteForum(params?.id);
    if (Math.floor(res?.status / 100) === 2) {
      navigate(-1, { replace: true });
    }
  };

  const handleRefresh = async () => {
    const res = await forumPost({ forum_id: params?.id });
    setPostData(res?.data);
  };

  return (
    <div className="forum-post">
      <div className="header">
        <img
          src={Logo}
          alt="BetterLady"
          width={"100px"}
          onClick={() => navigate("/")}
        />
        <div className="user-info-language">
          <ChooseLanguage />
          {userData?.username ? (
            <div className="user-info-container">
              <Notifications />

              <Dropdown
                menu={{
                  items,
                }}
                placement="bottomRight"
              >
                {userData?.profile_photo ? (
                  <div className="profile-photo">
                    <img
                      src={userData?.profile_photo}
                      alt={userData?.username}
                    />
                  </div>
                ) : (
                  <div className="user-initials">{getInitials()}</div>
                )}
              </Dropdown>
            </div>
          ) : (
            <div className="header-buttons">
              <Button className="brown" onClick={() => navigate("/signup")}>
                {t("signup")}
              </Button>
              <Button className="yellow" onClick={() => navigate("/login")}>
                {t("login")}
              </Button>
            </div>
          )}
        </div>
      </div>
      <hr />
      <div className="container">
        {loading ? (
          <div className="full-screen-overlay">
            <ClipLoader color={"#123abc"} size={50} />
          </div>
        ) : postData?.user_id ? (
          <div>
            <div className="user-details">
              <div className="user-data-container">
                <div
                  className="back-icon"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <FaArrowLeft style={{ height: "40px", width: "40px" }} />
                </div>
                {postData?.user_photo ? (
                  <div
                    className="profile"
                    onClick={(e) => {
                      navigate(
                        postData?.user_role === "user"
                          ? "/profile/" + postData.user_id
                          : "/shop/" + postData.user_id
                      );
                    }}
                  >
                    <img
                      src={
                        process.env.REACT_APP_BACKEND_PHOTO_URL +
                        postData?.user_photo
                      }
                      alt="profile"
                    />
                    <Level
                      backgroundColor={getColorForString(postData?.user_level)}
                      level={postData?.user_level}
                    />
                  </div>
                ) : (
                  <div
                    className="profile"
                    onClick={(e) => {
                      navigate(
                        postData?.user_role === "user"
                          ? "/profile/" + postData.user_id
                          : "/shop/" + postData.user_id
                      );
                    }}
                  >
                    <div className="user-initials">
                      {postData?.username
                        .split(" ")
                        .map((word) => word[0].toUpperCase())
                        .join("")}
                    </div>
                    <Level
                      backgroundColor={getColorForString(postData?.user_level)}
                      level={postData?.user_level}
                    />
                  </div>
                )}
                <div className="user-data">
                  <div
                    className="user-name"
                    onClick={(e) => {
                      navigate(
                        postData?.user_role === "user"
                          ? "/profile/" + postData.user_id
                          : "/shop/" + postData.user_id
                      );
                    }}
                  >
                    <p>{postData?.username}</p>
                  </div>
                  .
                  <div className="post-date">
                    Posted {calculateTimeAgo(postData?.date_posted)}
                  </div>
                </div>
              </div>
              <div className="actions">
                <div className="votes">
                  <span>{postData?.votes}</span>
                </div>

                <div
                  className={
                    postData?.my_vote
                      ? "action-button-selected"
                      : "action-button"
                  }
                  onClick={handleUpvote}
                >
                  <IoIosArrowUp className="action-button-icon" />
                </div>
              </div>
            </div>
            <hr />
            <div className="post-card forum-text-container">
              <div className="forum-text">
                <div className="heading">
                  <p>{postData?.forum_topic}</p>
                </div>
                <div className="description">
                  <p>{postData?.comment}</p>
                </div>
              </div>
              {postData?.user_id === userData?.id && (
                <Dropdown
                  trigger={["click"]}
                  menu={{
                    items: [
                      {
                        key: "1",
                        label: (
                          <Popconfirm
                            title={t("areYouSureToDeleteThisComment")}
                            onConfirm={() =>
                              handleDeletePost(postData?.forum_id)
                            }
                          >
                            {t("delete")}
                          </Popconfirm>
                        ),
                        icon: (
                          <Popconfirm
                            title={t("areYouSureToDeleteThisComment")}
                            onConfirm={() =>
                              handleDeleteComment(postData?.forum_id)
                            }
                          >
                            <DeleteOutlined />
                          </Popconfirm>
                        ),
                      },
                      {
                        key: "2",
                        label: t("edit"),
                        icon: <EditOutlined />,
                        onClick: () => handleUpdatePost(),
                      },
                      {
                        key: "3",
                        label: t("editDescription"),
                        icon: <EditOutlined />,
                        onClick: () => handleUpdatePostDescription(),
                      },
                    ],
                  }}
                >
                  <SlOptionsVertical style={{ cursor: "pointer" }} />
                </Dropdown>
              )}
            </div>
            <hr />
            <div className="comments">
              {postComments?.length ? (
                postComments.map((comment) => (
                  <div className="comment" key={comment?.comment_id}>
                    <div className="user-info">
                      {comment?.user_photo ? (
                        <div
                          className="profile-2"
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(
                              comment?.role === "user"
                                ? "/profile/" + comment.user_id
                                : "/shop/" + comment.user_id
                            );
                          }}
                        >
                          <img
                            src={
                              process.env.REACT_APP_BACKEND_PHOTO_URL +
                              comment?.user_photo
                            }
                            alt="profile"
                          />
                          <Level
                            backgroundColor={getColorForString(comment?.level)}
                            level={comment?.level}
                          />
                        </div>
                      ) : (
                        <div
                          className="profile-2"
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(
                              comment?.role === "user"
                                ? "/profile/" + comment.user_id
                                : "/shop/" + comment.user_id
                            );
                          }}
                        >
                          <div className="user-initials-2">
                            {comment?.username
                              ?.split(" ")
                              ?.map((word) => word[0].toUpperCase())
                              ?.join("")}
                          </div>
                          <Level
                            backgroundColor={getColorForString(comment?.level)}
                            level={comment?.level}
                          />
                        </div>
                      )}
                      <div className="comment-user-info">
                        <p className="name">{comment?.username}</p>
                        <p className="post-time">
                          Posted{" "}
                          {calculateTimeAgo(comment?.date_comment_posted)}
                        </p>
                        <p>{comment?.text}</p>
                      </div>
                      <div className="actions">
                        <div className="votes">
                          <span>{comment?.upvotes}</span>
                        </div>

                        <div
                          className={
                            comment?.user_vote
                              ? "action-button-selected"
                              : "action-button"
                          }
                          onClick={() => handleUpvoteComment(comment)}
                        >
                          <IoIosArrowUp className="action-button-icon" />
                        </div>
                        {comment?.user_id === userData?.id && (
                          <Dropdown
                            trigger={["click"]}
                            menu={{
                              items: [
                                {
                                  key: "1",
                                  label: (
                                    <Popconfirm
                                      title={t("areYouSureToDeleteThisComment")}
                                      onConfirm={() =>
                                        handleDeleteComment(comment?.comment_id)
                                      }
                                    >
                                      {t("delete")}
                                    </Popconfirm>
                                  ),
                                  icon: (
                                    <Popconfirm
                                      title={t("areYouSureToDeleteThisComment")}
                                      onConfirm={() =>
                                        handleDeleteComment(comment?.comment_id)
                                      }
                                    >
                                      <DeleteOutlined />
                                    </Popconfirm>
                                  ),
                                },
                              ],
                            }}
                          >
                            <SlOptionsVertical style={{ cursor: "pointer" }} />
                          </Dropdown>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="no-comments">
                  <p>{t("noComments")}</p>
                </div>
              )}
            </div>
            {commentsNextPageUrl && (
              <div className="load-more-wrapper">
                <Button onClick={fetchNextPageComments} loading={loadingData}>
                  {t("loadMore")}
                </Button>
              </div>
            )}
            <div className="add-comment">
              <p>{t("addComment")}</p>
              <Input.TextArea
                placeholder={t("addNewComment")}
                className="text-area"
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
              />
              <Button
                onClick={handleAddComment}
                loading={loadingCommentPost}
                disabled={loadingCommentPost}
              >
                {t("post")}
              </Button>
            </div>
          </div>
        ) : (
          <div className="no-comments">
            <p>{t("postNotFound")}</p>
          </div>
        )}
      </div>

      <Footer />
      {isEditModalOpen && (
        <NewDiscussionModal
          isModalOpen={isEditModalOpen}
          setIsModalOpen={setIsEditModalOpen}
          editData={editData}
          type={"edit"}
          handleRefresh={handleRefresh}
        />
      )}
      {isEditDescriptionModalOpen && (
        <NewDiscussionModal
          isModalOpen={isEditDescriptionModalOpen}
          setIsModalOpen={setIsEditDescriptionModalOpen}
          editData={editDescriptionData}
          type={"comment"}
          handleRefresh={handleRefresh}
        />
      )}
    </div>
  );
};

export default ForumPost;
