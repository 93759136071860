import { LogoutOutlined, SettingOutlined } from "@ant-design/icons";
import { Button, Dropdown } from "antd";
import React, { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Logo from "../../../Assets/Images/betterLady.png";
import { UserContext } from "../../../App";
import ChooseLanguage from "../../../Components/Common/ChooseLanguage";
import {
  handleLogout,
  handleUpdateUser,
} from "../../../Utils/UpdateUsersState";
import "./style.css";
import Footer from "../../../Components/Common/Footer";
import Notifications from "../../../Components/Common/Notifications";

const AboutUs = () => {
  const { userData, setUserData, setUserToken } = useContext(UserContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const items = [
    {
      key: "1",
      label: "Settings",
      icon: <SettingOutlined />,
      onClick: () => {
        handleUpdateUser(setUserData, { ...userData, account: true });
      },
    },
    {
      key: "2",
      label: "Logout",
      icon: <LogoutOutlined />,
      onClick: () => handleLogout(setUserData, setUserToken),
    },
  ];

  const getInitials = useCallback(() => {
    if (userData?.name) {
      return userData.name
        .split(" ")
        .map((word) => word[0].toUpperCase())
        .join("");
    }
  }, [userData?.name]);

  useEffect(() => {
    getInitials();
  }, [getInitials]);

  return (
    <div className="about-us">
      <div className="header">
        <img
          src={Logo}
          alt="BetterLady"
          width={"100px"}
          onClick={() => navigate("/")}
        />
        <div className="user-info-language">
          <ChooseLanguage />
          {userData?.username ? (
            <div className="user-info-container">
              <Notifications />

              <Dropdown
                menu={{
                  items,
                }}
                placement="bottomRight"
              >
                {userData?.profile_photo ? (
                  <div className="profile-photo">
                    <img
                      src={userData?.profile_photo}
                      alt={userData?.username}
                    />
                  </div>
                ) : (
                  <div className="user-initials">{getInitials()}</div>
                )}
              </Dropdown>
            </div>
          ) : (
            <div className="header-buttons">
              <Button className="brown" onClick={() => navigate("/signup")}>
                {t("signup")}
              </Button>
              <Button className="yellow" onClick={() => navigate("/login")}>
                {t("login")}
              </Button>
            </div>
          )}
        </div>
      </div>
      <hr />
      <div className="container">
        <div className="heading">
          <p>{t("aboutUs")}</p>
        </div>
        <div className="intro">
          <p>{t("aboutUsIntro")}</p>
        </div>
        <div className="data-container">
          <div className="question">
            <p>{t("ourStory")}</p>
          </div>
          <div className="answer">
            <p>{t("aboutUsOurStoryData")}</p>
            <p>{t("aboutUsOurStoryData2")}</p>
            <p>{t("aboutUsOurStoryData3")}</p>
            <p>{t("aboutUsOurStoryData4")}</p>
            <p>{t("aboutUsOurStoryData5")}</p>
          </div>
        </div>
        <div className="data-container">
          <div className="question">
            <p>{t("getInTouch")}</p>
          </div>
          <div className="answer">
            <p>{t("getInTouchData")}</p>
          </div>
        </div>
        <div className="data-container">
          <div className="intro">
            <p>{t("aboutUsThanks")}</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
