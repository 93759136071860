import axios from "axios";
import { getToken } from "../Utils/UpdateUsersState";

const getNotifications = async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}notification/`,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
  }
};

const markAsRead = async (notification_id) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}notification/status/`,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
        params: { notification_id },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
  }
};

const notificationURL = async (url) => {
  try {
    const res = await axios.get(url, {
      headers: { Authorization: `Bearer ${getToken()}` },
    });
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export { getNotifications, markAsRead, notificationURL };
