import {
  DeleteOutlined,
  EditOutlined,
  LogoutOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Badge, Button, Dropdown, Popconfirm } from "antd";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Logo from "../../../Assets/Images/betterLady.png";
import { UserContext } from "../../../App";
import ChooseLanguage from "../../../Components/Common/ChooseLanguage";
import {
  handleLogout,
  handleUpdateUser,
} from "../../../Utils/UpdateUsersState";
import "./style.css";
import Footer from "../../../Components/Common/Footer";
import ForumButton from "../../../Components/Common/Forum Button";
import { LiaFilterSolid } from "react-icons/lia";
import FilterModal from "./FilterModal";
import { IoIosArrowUp } from "react-icons/io";
import Search from "antd/es/input/Search";
import Level from "../../../Components/Common/Level";
import {
  deleteForum,
  forumSearch,
  forumUrl,
  getCategories,
  getForum,
  upvote,
} from "../../../API/forum";
import { calculateTimeAgo } from "../../../Utils/Helper/timeDifference";
import { ClipLoader } from "react-spinners";
import { getColorForString } from "../../../Utils/Helper/levelColor";
import { SlOptionsVertical } from "react-icons/sl";
import NewDiscussionModal from "../../../Components/Common/Forum Button/NewDiscussionModal";
import Notifications from "../../../Components/Common/Notifications";

const Forum = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editData, setEditData] = useState(false);
  const { userData, setUserData, setUserToken } = useContext(UserContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [filters, setFilters] = useState([]);
  const [categories, setCategories] = useState([]);
  const [forumData, setForumData] = useState([]);
  const [nextPageUrl, setNextPageUrl] = useState();
  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(false);
  const items = [
    {
      key: "1",
      label: "Settings",
      icon: <SettingOutlined />,
      onClick: () => {
        handleUpdateUser(setUserData, { ...userData, account: true });
      },
    },
    {
      key: "2",
      label: "Logout",
      icon: <LogoutOutlined />,
      onClick: () => handleLogout(setUserData, setUserToken),
    },
  ];
  const getInitials = useCallback(() => {
    if (userData?.name) {
      return userData.name
        .split(" ")
        .map((word) => word[0].toUpperCase())
        .join("");
    }
  }, [userData?.name]);
  useEffect(() => {
    getInitials();
  }, [getInitials]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await getCategories();
      setCategories(res?.data);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const res = await getForum(filters);
      if (Math.floor(res?.status / 100 === 2)) {
        setForumData(res?.data?.results);
        setNextPageUrl(res?.data?.next);
      }
      setLoading(false);
    };
    fetchData();
  }, [filters]);

  const fetchNextPageForum = async () => {
    setLoadingData(true);
    const res = await forumUrl(nextPageUrl);
    if (Math.floor(res?.status / 100) === 2) {
      setForumData([...forumData, ...res?.data?.results]);
      setNextPageUrl(res?.data?.next);
    } else {
      // api["error"]({
      //   message: t("fetchingFailed"),
      //   description: res?.response?.data?.detail,
      // });
    }
    setLoadingData(false);
  };

  const handleUpvote = async (data) => {
    // try {
    // } catch (e) {
    //   console.log(e);
    // }
    const res = await upvote({
      forum_id: data?.forum_id,
      vote: !data?.user_vote,
    });
    const list = forumData.map((item) => {
      return item?.forum_id === res?.data?.forum_id
        ? { ...item, upvotes: res?.data?.upvotes, user_vote: res?.data?.vote }
        : item;
    });
    setForumData(list);
  };

  const handleSearch = async (search) => {
    const res = search ? await forumSearch({ search }) : await getForum();
    setForumData(res?.data?.results);
    setNextPageUrl(res?.data?.next);
  };

  const handleRefresh = async () => {
    setLoading(true);
    const res = await getForum();
    setForumData(res?.data?.results);
    setNextPageUrl(res?.data?.next);
    setFilters([]);
    setLoading(true);
  };

  const handleUpdatePost = async (data) => {
    setIsEditModalOpen(true);
    setEditData({
      category: data?.category,
      topic: data?.forum_topic,
      forum_id: data?.forum_id,
    });
  };

  const handleDeletePost = async (id) => {
    const res = await deleteForum(id);
    if (Math.floor(res?.status / 100) === 2) {
      handleRefresh();
    }
  };

  return (
    <div className="forum">
      <ForumButton type={"newPost"} handleRefresh={handleRefresh} />
      <div className="header">
        <img
          src={Logo}
          alt="BetterLady"
          width={"100px"}
          onClick={() => navigate("/")}
        />
        <div className="user-info-language">
          <ChooseLanguage />
          {userData?.username ? (
            <div className="user-info-container">
              <Notifications />

              <Dropdown
                menu={{
                  items,
                }}
                placement="bottomRight"
              >
                {userData?.profile_photo ? (
                  <div className="profile-photo">
                    <img
                      src={userData?.profile_photo}
                      alt={userData?.username}
                    />
                  </div>
                ) : (
                  <div className="user-initials">{getInitials()}</div>
                )}
              </Dropdown>
            </div>
          ) : (
            <div className="header-buttons">
              <Button className="brown" onClick={() => navigate("/signup")}>
                {t("signup")}
              </Button>
              <Button className="yellow" onClick={() => navigate("/login")}>
                {t("login")}
              </Button>
            </div>
          )}
        </div>
      </div>
      <hr />
      <div className="container">
        <div className="heading-container">
          <div className="heading">
            <p>{t("betterLadyForum")}</p>
          </div>
          <Badge count={filters?.length} color={"#bbaad3"}>
            <Button
              onClick={() => {
                setIsModalOpen(true);
              }}
              icon={
                <LiaFilterSolid style={{ height: "20px", width: "20px" }} />
              }
              className="filter-button"
            >
              {t("categories")}
            </Button>
          </Badge>
        </div>

        <div className="searchbar">
          <Search placeholder={t("search")} onSearch={handleSearch} />
        </div>
        {loading ? (
          <div className="full-screen-overlay">
            <ClipLoader color={"#123abc"} size={50} />
          </div>
        ) : (
          <div className="posts">
            {forumData?.length ? (
              forumData?.map((post) => (
                <div
                  className="post-card"
                  key={post?.forum_id}
                  onClick={() => {
                    navigate("/forum/" + post.forum_id);
                  }}
                >
                  {post?.user_photo ? (
                    <div
                      className="profile"
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(
                          post?.role === "user"
                            ? "/profile/" + post.user_id
                            : "/shop/" + post.user_id
                        );
                      }}
                    >
                      <img
                        src={
                          process.env.REACT_APP_BACKEND_PHOTO_URL +
                          post?.user_photo
                        }
                        alt="profile"
                      />
                      <Level
                        backgroundColor={getColorForString(post?.level)}
                        level={post?.level}
                      />
                    </div>
                  ) : (
                    <div
                      className="profile"
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(
                          post?.role === "user"
                            ? "/profile/" + post.user_id
                            : "/shop/" + post.user_id
                        );
                      }}
                    >
                      <div className="user-initials">
                        {post?.name
                          ?.split(" ")
                          ?.map((word) => word[0].toUpperCase())
                          ?.join("")}
                      </div>
                      <Level
                        backgroundColor={getColorForString(post?.level)}
                        level={post?.level}
                      />
                    </div>
                  )}

                  <div className="data-container">
                    <div className="body">
                      <div className="title">{post?.forum_topic}</div>
                      <div className="data">
                        <div>
                          <span
                            className="clickable-text"
                            onClick={(e) => {
                              e.stopPropagation();
                              navigate(
                                post?.role === "user"
                                  ? "/profile/" + post.user_id
                                  : "/shop/" + post.user_id
                              );
                            }}
                          >
                            {post?.username}
                          </span>
                          . <span> Last comment </span>
                          {calculateTimeAgo(post?.last_comment_date)}
                          <span> by </span>
                          <span
                            className="clickable-text"
                            onClick={(e) => {
                              e.stopPropagation();
                              navigate(
                                post?.role === "user"
                                  ? "/profile/" + post.last_comment_user_id
                                  : "/shop/" + post.last_comment_user_id
                              );
                            }}
                          >
                            {post?.last_comment_user}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className="action-details"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <div className="actions">
                        <div className="votes">
                          <span>{post?.upvotes}</span>
                        </div>

                        <div
                          className={
                            post?.user_vote
                              ? "action-button-selected"
                              : "action-button"
                          }
                          onClick={() => {
                            handleUpvote(post);
                          }}
                        >
                          <IoIosArrowUp className="action-button-icon" />
                        </div>
                        {post?.user_id === userData?.id && (
                          <Dropdown
                            trigger={["click"]}
                            menu={{
                              items: [
                                {
                                  key: "1",
                                  label: (
                                    <Popconfirm
                                      title={t("areYouSureToDeleteThisPost")}
                                      onConfirm={() =>
                                        handleDeletePost(post?.forum_id)
                                      }
                                    >
                                      {t("delete")}
                                    </Popconfirm>
                                  ),
                                  icon: (
                                    <Popconfirm
                                      title={t("areYouSureToDeleteThisPost")}
                                      onConfirm={() =>
                                        handleDeletePost(post?.forum_id)
                                      }
                                    >
                                      <DeleteOutlined />
                                    </Popconfirm>
                                  ),
                                },
                                {
                                  key: "2",
                                  label: t("edit"),
                                  icon: <EditOutlined />,
                                  onClick: () => handleUpdatePost(post),
                                },
                              ],
                            }}
                          >
                            <SlOptionsVertical style={{ cursor: "pointer" }} />
                          </Dropdown>
                        )}
                      </div>
                      <div className="details">
                        {post?.comments ? (
                          post.comments === 1 ? (
                            <span>1 {t("comment")}</span>
                          ) : (
                            <span>
                              {post?.comments} {t("comments")}
                            </span>
                          )
                        ) : (
                          <span>{t("noComments")}</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="no-posts">
                <p>{t("noPosts")}</p>
              </div>
            )}
            {nextPageUrl && (
              <div className="load-more-wrapper">
                <Button onClick={fetchNextPageForum} loading={loadingData}>
                  {t("loadMore")}
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
      <Footer />
      {isModalOpen && (
        <FilterModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          setFilters={setFilters}
          filters={filters}
          categories={categories}
        />
      )}
      {isEditModalOpen && (
        <NewDiscussionModal
          isModalOpen={isEditModalOpen}
          setIsModalOpen={setIsEditModalOpen}
          editData={editData}
          type={"edit"}
          handleRefresh={handleRefresh}
        />
      )}
    </div>
  );
};

export default Forum;
