import axios from "axios";
import { getToken } from "../Utils/UpdateUsersState";

const createForum = async (data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}forum/`,
      data,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const getForum = async (filters) => {
  try {
    const categories = filters?.length
      ? { categories: JSON.stringify(filters) }
      : null;
    const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}forum/`, {
      params: categories,
      headers: { Authorization: `Bearer ${getToken()}` },
    });
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const updateForum = async (data, forum_id) => {
  try {
    const res = await axios.patch(
      `${process.env.REACT_APP_BACKEND_URL}forum/`,
      data,
      {
        params: { forum_id },
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const updateComment = async (data, comment_id) => {
  try {
    const res = await axios.patch(
      `${process.env.REACT_APP_BACKEND_URL}comment/`,
      data,
      {
        params: { comment_id },
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const deleteForum = async (forum_id) => {
  try {
    const res = await axios.delete(
      `${process.env.REACT_APP_BACKEND_URL}forum/`,
      {
        params: { forum_id },
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const forumUrl = async (url) => {
  try {
    const res = await axios.get(url, {
      headers: { Authorization: `Bearer ${getToken()}` },
    });
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const commentUrl = async (url) => {
  try {
    const res = await axios.get(url, {
      headers: { Authorization: `Bearer ${getToken()}` },
    });
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const upvote = async (data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}vote/forum/`,
      data,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const upvoteComment = async (data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}vote/comment/`,
      data,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const deleteComment = async (comment_id) => {
  try {
    const res = await axios.delete(
      `${process.env.REACT_APP_BACKEND_URL}comment/`,
      {
        params: { comment_id },
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const forumSearch = async (data) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}search/forum/`,
      {
        params: data,
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const forumPost = async (data) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}post/`, {
      params: data,
      headers: { Authorization: `Bearer ${getToken()}` },
    });
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const forumPostComments = async (data) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}comment/`,
      {
        params: data,
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const addComment = async (data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}comment/`,
      data,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const getCategories = async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}forum/category/`,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export {
  createForum,
  getForum,
  upvote,
  upvoteComment,
  forumUrl,
  forumSearch,
  forumPost,
  forumPostComments,
  commentUrl,
  addComment,
  getCategories,
  updateForum,
  deleteForum,
  deleteComment,
  updateComment,
};
