import { Input, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { discover, discoverUrl } from "../../../API/shop";
import Footer from "../../../Components/Common/Footer";
import ForumButton from "../../../Components/Common/Forum Button";
import Navbar from "../../../Components/Layout/Navbar";
import Shops from "./Shops";
import "./style.css";

const Discover = () => {
  const { t } = useTranslation();
  const [discoverData, setDiscoverData] = useState([]);
  const [nextUrl, setNextUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const { Search } = Input;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const res = await discover();
      setDiscoverData(res?.data?.results);
      setNextUrl(res?.data?.next);
      setLoading(false);
    };
    fetchData();
  }, []);

  const fetchNextPageReviews = async () => {
    setLoadingData(true);
    const res = await discoverUrl(nextUrl);
    if (Math.floor(res?.status / 100) === 2) {
      setDiscoverData([...discoverData, ...res?.data?.results]);
      setNextUrl(res?.data?.next);
    } else {
      api["error"]({
        message: t("fetchingFailed"),
        description: res?.response?.data?.detail,
      });
    }
    setLoadingData(false);
  };

  const handleSearch = async (e) => {
    navigate("/discover/" + e, { state: { keywords: e } });
  };

  return (
    <>
      {contextHolder}
      <div className="discover">
        <ForumButton type={"forum"} />
        <Navbar />
        <div className="heading">
          <p>{t("discoverBeautyShops")}</p>
          <Search
            placeholder={t("searchBeautyShops")}
            className="searchBar"
            onSearch={handleSearch}
          />
        </div>
        <hr />
        <div className="body-container">
          <div className="shops-wrapper">
            <Shops
              items={discoverData}
              setItems={setDiscoverData}
              fetchNextPageReviews={fetchNextPageReviews}
              nextUrl={nextUrl}
              loading={loading}
              loadingData={loadingData}
            />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Discover;
