import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Signin from "../Pages/Public/Auth/Signin";
import Signup from "../Pages/Public/Auth/SignUp";
import Discover from "../Pages/Public/Discover";
import Home from "../Pages/Public/Home";
import SearchComponent from "../Pages/Public/Search";
import Profile from "../Pages/Public/Profile";
import Shop from "../Pages/Public/Shop";
import ForgotPassword from "../Pages/Public/Auth/ForgotPassword";
import ResetPassword from "../Pages/Public/Auth/ResetPassword";
import FAQs from "../Pages/Public/FAQs";
import PrivacyPolicy from "../Pages/Public/PrivacyPolicy";
import TermsAndConditions from "../Pages/Public/Terms&Conditions";
import ContactUs from "../Pages/Public/ContactUs";
import AboutUs from "../Pages/Public/AboutUs";
import Forum from "../Pages/Public/Forum";
import ForumPost from "../Pages/Public/ForumPost";

const PublicRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/discover" element={<Discover />} />
        <Route path="/discover/:id" element={<SearchComponent />} />
        <Route path="/profile/:id" element={<Profile />} />
        <Route path="/shop/:id" element={<Shop />} />
        <Route path="/login" element={<Signin />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/reset-password/:uuid" element={<ResetPassword />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/faq" element={<FAQs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms&conditions" element={<TermsAndConditions />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/forum" element={<Forum />} />
        <Route path="/forum/:id" element={<ForumPost />} />
        <Route path="*" element={<Navigate to={"/"} />} />
      </Routes>
    </>
  );
};

export default PublicRoutes;
