import {
  Button,
  Progress,
  Tabs,
  ConfigProvider,
  notification,
  Popconfirm,
  message,
  Image,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Footer from "../../../Components/Common/Footer";
import Navbar from "../../../Components/Layout/Navbar";
import "./style.css";
import { DeleteOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { getShopProfile, getShopServices } from "../../../API/profile";
import { ClipLoader } from "react-spinners";
import { MdWhatsapp } from "react-icons/md";
import { deleteReview, getReviewsURL, shopReviews } from "../../../API/rating";
import ReviewModal from "../../../Components/Common/ReviewModal";
import ProfilePhoto from "../../../Assets/Images/profile.png";
import { UserContext } from "../../../App";
import { IoLogoInstagram } from "react-icons/io5";
import {
  getInstagramUsername,
  handleInstagram,
  handleWhatsapp,
} from "../../../Utils/Helper/socialActions";
import ForumButton from "../../../Components/Common/Forum Button";

const Shop = () => {
  const { t } = useTranslation();
  const shopId = useParams()?.id;
  const [shop, setShop] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [api, contextHolder] = notification.useNotification();
  const [nextUrl, setNextUrl] = useState(null);
  const [loadingReviews, setLoadingReviews] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const navigate = useNavigate();
  const { userData } = useContext(UserContext);

  useEffect(() => {
    const func = async () => {
      setLoading(true);
      const res = await getShopProfile(shopId);
      const reviewsData = await shopReviews(shopId);
      if (res?.status === 200 && reviewsData.status === 200) {
        setShop(res?.data);
        setReviews(reviewsData?.data?.results);
        setNextUrl(reviewsData.data?.next);
      } else {
        if (res?.status === 200)
          api["error"]({
            message: t("fetchingFailed"),
            description: reviewsData?.response?.data?.detail,
          });
        else
          api["error"]({
            message: t("fetchingFailed"),
            description: res?.response?.data?.detail,
          });
      }
      setLoading(false);
    };
    func();
  }, [shopId, t, api, refresh]);

  useEffect(() => {
    const func = async () => {
      const res = await getShopServices(shopId);
      if (res?.status === 200) {
        setServices(res?.data);
      } else {
        api["error"]({
          message: t("fetchingFailed"),
          description: res?.response?.data?.detail,
        });
      }
    };
    func();
  }, [refresh]);

  const fetchNextPageReviews = async () => {
    setLoadingReviews(true);
    const res = await getReviewsURL(nextUrl);
    if (res?.status === 200) {
      setReviews([...reviews, ...res?.data?.results]);
      setNextUrl(res?.data?.next);
    } else {
      api["error"]({
        message: t("fetchingFailed"),
        description: res?.response?.data?.detail,
      });
    }
    setLoadingReviews(false);
  };

  const handleModal = () => {
    setOpenModal(true);
  };

  const rating = (number) => {
    if (number >= 0 && number < 5) {
      return t("belowAverage");
    } else if (number >= 5 && number < 7) {
      return t("aboveAverage");
    } else if (number >= 7 && number < 8) {
      return t("good");
    } else if (number >= 8) {
      return t("highlyRated");
    }
  };

  const items = [
    {
      key: "1",
      label: t("perks"),
      children: shop?.shop_perks?.length ? (
        <div>
          {shop?.shop_perks?.map((perk, index) => (
            <div className="tag" key={index}>
              <p className="tag-text">#{perk}</p>
            </div>
          ))}
        </div>
      ) : (
        <div className="tab-item">{t("perksAppearHere")}</div>
      ),
    },
    {
      key: "2",
      label: t("tags"),
      children: shop?.shop_tags?.length ? (
        <div>
          {shop?.shop_tags?.map((tag, index) => (
            <div className="tag" key={index}>
              <p className="tag-text">#{tag}</p>
            </div>
          ))}
        </div>
      ) : (
        <div className="tab-item">{t("tagsAppearHere")}</div>
      ),
    },
  ];

  const confirm = async (e) => {
    try {
      const res = await deleteReview(e);
      message.success(t("reviewDeleted"));
      setRefresh(!refresh);
    } catch (e) {
      message.error(e);
    }
  };

  return (
    <div className="shop">
      <ForumButton type={"forum"} />
      {contextHolder}
      <Navbar title={t("shop")} />
      <hr />
      <div className="shop-body">
        {loading ? (
          <div className="full-screen-overlay">
            <ClipLoader color={"#123abc"} size={50} />
          </div>
        ) : shop ? (
          <>
            <div className="images">
              <div className="main-image">
                {shop?.shop_photos?.length ? (
                  <Image
                    src={
                      process.env.REACT_APP_BACKEND_PHOTO_URL +
                      shop?.shop_photos?.filter(
                        (photo) => photo?.main === true
                      )[0]?.url
                    }
                    alt={t("shopMainPhoto")}
                  />
                ) : null}
              </div>
              <div className="image-container">
                {shop?.shop_photos
                  ?.filter((photo) => photo?.main === false)
                  ?.map((photo, index) => (
                    <div className="img-wrap" key={index}>
                      <Image
                        src={
                          process.env.REACT_APP_BACKEND_PHOTO_URL + photo?.url
                        }
                        alt={t("shopPhoto")}
                      />
                    </div>
                  ))}
              </div>
            </div>
            <div className="text-body-container">
              <div className="shop-details">
                <p className="heading">{shop?.shop_name}</p>
                <p className="text">{shop?.shop_description}</p>
                <div>
                  {shop?.whatsapp && (
                    <div
                      className="shop-info"
                      onClick={() => handleWhatsapp(shop.whatsapp)}
                    >
                      <MdWhatsapp color="green" className="social-icon" />
                      <span className="text">+{shop?.whatsapp}</span>
                    </div>
                  )}
                  {shop?.instagram && (
                    <div
                      className="shop-info"
                      onClick={() => handleInstagram(shop?.instagram)}
                    >
                      <IoLogoInstagram color="purple" className="social-icon" />
                      <span className="text">
                        @{getInstagramUsername(shop?.instagram)}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="shop-stats">
                <div className="stat-info">
                  <div className="rating">
                    <p>{shop?.average_ratings?.overall_average}</p>
                  </div>
                  <p className="subheading">
                    {rating(shop?.average_ratings?.overall_average)}
                  </p>
                </div>
                {userData?.role !== "shop" && (
                  <Button className="review-button" onClick={handleModal}>
                    {t("addAReview")}
                  </Button>
                )}
              </div>
            </div>
            <div className="tabs-container">
              <ConfigProvider
                theme={{
                  components: {
                    Tabs: {
                      itemSelectedColor: "#f0e569",
                      itemHoverColor: "##f0e569",
                      itemActiveColor: "#f0e569",
                      inkBarColor: "#f0e569",
                    },
                  },
                }}
              >
                <Tabs defaultActiveKey="1" items={items} />
              </ConfigProvider>
            </div>
            <div className="services">
              <div className="services-container">
                <p className="heading">{t("servicesOfffered")}</p>
                <div className="services-list">
                  {services.map((item, index) => (
                    <div className="service-card" key={index}>
                      <img
                        src={
                          item?.photo_url
                            ? process.env.REACT_APP_BACKEND_PHOTO_URL +
                              item?.photo_url
                            : "https://upload.wikimedia.org/wikipedia/commons/thumb/1/11/Test-Logo.svg/1200px-Test-Logo.svg.png"
                        }
                        alt="service"
                      />
                      <p className="textheading">{item?.title}</p>
                      <div className="specifications">
                        <div className="info">
                          <p className="subtext">{item?.description}</p>
                        </div>
                      </div>
                      <Button className="review-button">${item?.price}</Button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="reviews">
              <div>
                <p className="heading">{t("reviews")}</p>
                <div className="review-container">
                  <div className="ratings">
                    <p className="rating">
                      {shop?.average_ratings?.overall_average}/10
                    </p>
                    <div className="progress">
                      <div className="percentage">
                        <p>{t("cleanliness")}</p>
                        <Progress
                          percent={
                            shop?.average_ratings?.average_ratings?.clean * 10
                          }
                          strokeColor="#FAEF7C"
                        />
                      </div>
                      <div className="percentage">
                        <p>{t("ambiance")}</p>
                        <Progress
                          percent={
                            shop?.average_ratings?.average_ratings?.ambiance *
                            10
                          }
                          strokeColor="#FAEF7C"
                        />
                      </div>
                      <div className="percentage">
                        <p>{t("hospitality")}</p>
                        <Progress
                          percent={
                            shop?.average_ratings?.average_ratings
                              ?.hospitality * 10
                          }
                          strokeColor="#FAEF7C"
                        />
                      </div>
                      <div className="percentage">
                        <p>{t("service")}</p>
                        <Progress
                          percent={
                            shop?.average_ratings?.average_ratings?.service * 10
                          }
                          strokeColor="#FAEF7C"
                        />
                      </div>
                      <div className="percentage">
                        <p>{t("value")}</p>
                        <Progress
                          percent={
                            shop?.average_ratings?.average_ratings?.value * 10
                          }
                          strokeColor="#FAEF7C"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="comments">
                    {reviews?.map((item, index) => {
                      return (
                        <div className="comment-container" key={index}>
                          <div className="image-container">
                            <img
                              src={
                                item?.profile_photo
                                  ? process.env.REACT_APP_BACKEND_PHOTO_URL +
                                    item?.profile_photo
                                  : ProfilePhoto
                              }
                              alt="profile"
                              className="navigator"
                              onClick={() => navigate("/profile/" + item?.user)}
                            />
                          </div>
                          <div className="data-container">
                            <div className="name-text">
                              <div>
                                <p
                                  className="navigator"
                                  onClick={() =>
                                    navigate("/profile/" + item?.user)
                                  }
                                >
                                  {item?.user_name}
                                </p>
                                <p className="date">{item?.date}</p>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "10px",
                                }}
                              >
                                <p className="rating-value">
                                  {item?.average_rating}
                                </p>
                                {item?.user === userData?.id && (
                                  <Popconfirm
                                    title={t("deleteReview")}
                                    description={t("deleteReviewConfirm")}
                                    onConfirm={() => confirm(item?.id)}
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <DeleteOutlined />
                                  </Popconfirm>
                                )}
                              </div>
                            </div>
                            <p className="comment-body">{item?.remarks}</p>
                            <div className="visit-information">
                              <p>
                                {t("dateOfVisit")}: {item?.date_of_visit}
                              </p>
                              <p>
                                {t("treatmentDuration")}:{" "}
                                {item?.treatment_duration} {t("hours")}
                              </p>
                              <p>
                                {t("treatmentSpending")}:{" "}
                                {item?.treatment_spending}
                              </p>
                            </div>
                            <div className="review-images">
                              {item?.photo_urls?.map((photo, index) => (
                                <div key={index}>
                                  <Image
                                    src={
                                      process.env.REACT_APP_BACKEND_PHOTO_URL +
                                      photo
                                    }
                                    alt="review"
                                  />
                                </div>
                              ))}
                            </div>
                            <div className="rating-types">
                              <p>
                                {t("service")}: {item?.service}
                              </p>
                              <p>
                                {t("cleanliness")}: {item?.clean}
                              </p>
                              <p>
                                {t("hospitality")}: {item?.hospitality}
                              </p>
                              <p>
                                {t("ambiance")}: {item?.ambiance}
                              </p>
                              <p>
                                {t("value")}: {item?.value}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    {nextUrl && (
                      <Button
                        className="load-button"
                        onClick={fetchNextPageReviews}
                        loading={loadingReviews}
                      >
                        {t("loadMore")}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
              <div></div>
            </div>
          </>
        ) : (
          <div className="no-data">
            <p>{t("shopDoesNotExist")}</p>
          </div>
        )}
      </div>
      <Footer />
      {openModal && (
        <ReviewModal
          open={openModal}
          setOpen={setOpenModal}
          shopId={shopId}
          setRefresh={setRefresh}
        />
      )}
    </div>
  );
};

export default Shop;
