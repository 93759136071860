import axios from "axios";
import { getToken } from "../Utils/UpdateUsersState";

const register = async (data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}register/`,
      data
    );
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const login = async (data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}token/`,
      data
    );
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const forgot = async (data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}forgot-password/`,
      data
    );
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const reset = async (data, uuid) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}reset-password/${uuid}/`,
      data
    );
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const refresh = async (refresh) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}token/refresh/`,
      { refresh },
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    );
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export { register, login, refresh, forgot, reset };
