import React, { useState } from "react";
import "./style.css";
import Logo from "../../../../Assets/Images/betterLady.png";
import { Button, Form, Input, notification } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import LanguageSelector from "../../../../Components/Common/LanguageSelector";
import { useTranslation } from "react-i18next";
import { reset } from "../../../../API/auth";

const ResetPassword = () => {
  const navigate = useNavigate();
  const { uuid } = useParams();
  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    const res = await reset(values, uuid);
    if (Math.floor(res?.status / 100) === 2) {
      api["success"]({
        message: t("passwordChanged"),
        description: t("mayLogin"),
      });
      
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } else {
      api["error"]({
        message: t("invalidLink"),
        description: t("newLink"),
      });
    }
    setLoading(false);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <>
      {contextHolder}
      <div className="signin">
        <LanguageSelector />
        <div className="container">
          <img
            src={Logo}
            alt="better-lady"
            width={"100px"}
            onClick={() => navigate("/")}
          />
          <p className="heading">{t("please-enter-new-password")}</p>
          <Form
            style={{
              width: 350,
              marginTop: "40px",
            }}
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label={t("new-password")}
              name="password"
              style={{ marginBottom: "5px" }}
              rules={[
                {
                  required: true,
                  message: t("passwordWarning"),
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label={t("confirm-password")}
              name="confirm-password"
              style={{ marginBottom: "5px" }}
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                  message: t("passwordsDoNotMatch"),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("The two passwords do not match")
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item>
              <div className="message-container">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="submit-button"
                  disabled={loading}
                  loading={loading}
                >
                  {t("reset-password")}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
