import { Modal, Select } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const FilterModal = ({
  setIsModalOpen,
  isModalOpen,
  setFilters,
  filters,
  categories,
}) => {
  const { t } = useTranslation();
  const { Option, OptGroup } = Select;
  const [selectedSubOptions, setSelectedSubOptions] = useState(filters);

  const handleChange = (value) => {
    setSelectedSubOptions(value);
  };

  const handleOk = () => {
    setFilters(selectedSubOptions);
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal
      title={t("selectCategory")}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okButtonProps={{
        className: "ok-button",
      }}
      cancelButtonProps={{
        className: "cancel-button",
      }}
      cancelText={t("cancel")}
      okText={t("select")}
    >
      <Select
        mode="multiple"
        style={{ width: "100%" }}
        placeholder="Select suboptions"
        onChange={handleChange}
        value={selectedSubOptions}
      >
        <OptGroup label={t("careerCorner")}>
          {categories.career_corner.map((item) => (
            <Option value={item?.value} key={item?.value}>
              {item?.label}
            </Option>
          ))}
        </OptGroup>
        <OptGroup label={t("chatAndChatterCorner")}>
          {categories.chat_chatter.map((item) => (
            <Option value={item?.value} key={item?.value}>
              {item?.label}
            </Option>
          ))}
        </OptGroup>
        <OptGroup label={t("promotionsAndOffers")}>
          {categories.promotion_offer.map((item) => (
            <Option value={item?.value} key={item?.value}>
              {item?.label}
            </Option>
          ))}
        </OptGroup>
      </Select>
    </Modal>
  );
};

export default FilterModal;
