import { LogoutOutlined, SettingOutlined } from "@ant-design/icons";
import { Button, Dropdown } from "antd";
import React, { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Logo from "../../../Assets/Images/betterLady.png";
import { UserContext } from "../../../App";
import ChooseLanguage from "../../../Components/Common/ChooseLanguage";
import {
  handleLogout,
  handleUpdateUser,
} from "../../../Utils/UpdateUsersState";
import "./style.css";
import Footer from "../../../Components/Common/Footer";
import Notifications from "../../../Components/Common/Notifications";

const FAQs = () => {
  const { userData, setUserData, setUserToken } = useContext(UserContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const items = [
    {
      key: "1",
      label: "Settings",
      icon: <SettingOutlined />,
      onClick: () => {
        handleUpdateUser(setUserData, { ...userData, account: true });
      },
    },
    {
      key: "2",
      label: "Logout",
      icon: <LogoutOutlined />,
      onClick: () => handleLogout(setUserData, setUserToken),
    },
  ];

  const getInitials = useCallback(() => {
    if (userData?.name) {
      return userData.name
        .split(" ")
        .map((word) => word[0].toUpperCase())
        .join("");
    }
  }, [userData?.name]);

  useEffect(() => {
    getInitials();
  }, [getInitials]);

  return (
    <div className="faq">
      <div className="header">
        <img
          src={Logo}
          alt="BetterLady"
          width={"100px"}
          onClick={() => navigate("/")}
        />
        <div className="user-info-language">
          <ChooseLanguage />
          {userData?.username ? (
            <div className="user-info-container">
              <Notifications />

              <Dropdown
                menu={{
                  items,
                }}
                placement="bottomRight"
              >
                {userData?.profile_photo ? (
                  <div className="profile-photo">
                    <img
                      src={userData?.profile_photo}
                      alt={userData?.username}
                    />
                  </div>
                ) : (
                  <div className="user-initials">{getInitials()}</div>
                )}
              </Dropdown>
            </div>
          ) : (
            <div className="header-buttons">
              <Button className="brown" onClick={() => navigate("/signup")}>
                {t("signup")}
              </Button>
              <Button className="yellow" onClick={() => navigate("/login")}>
                {t("login")}
              </Button>
            </div>
          )}
        </div>
      </div>
      <hr />
      <div className="container">
        <div className="heading">{t("faq")}</div>
        <div className="data-container">
          <div className="question">{t("whatIsBetterLady")}</div>
          <div className="answer">{t("betterLadyAnswer")}</div>
        </div>
        <div className="data-container">
          <div className="question">{t("platformFreeOfCharge")}</div>
          <div className="answer">{t("platformFreeOfChargeAnswer")}</div>
        </div>
        <div className="data-container">
          <div className="question">{t("howToFindBeautySalon")}</div>
          <div className="answer">{t("howToFindBeautySalonAnswer")}</div>
        </div>
        <div className="data-container">
          <div className="question">{t("canILeaveReview")}</div>
          <div className="answer">{t("canILeaveReviewAnswer")}</div>
        </div>
        <div className="data-container">
          <div className="question">{t("reviewsAuthentic")}</div>
          <div className="answer">{t("reviewsAuthenticAnswer")}</div>
        </div>
        <div className="data-container">
          <div className="question">{t("howToContactTeam")}</div>
          <div className="answer">{t("howToContactTeamAnswer")}</div>
        </div>
        <div className="data-container">
          <div className="question">{t("specialDealsBeautySalons")}</div>
          <div className="answer">{t("specialDealsBeautySalonsAnswer")}</div>
        </div>
        <div className="data-container">
          <div className="question">{t("reportInappropriateReview")}</div>
          <div className="answer">{t("reportInappropriateReviewAnswer")}</div>
        </div>
        <div className="data-container">
          <div className="question">{t("handlingNegativeComments")}</div>
          <div className="answer">{t("handlingNegativeCommentsAnswer")}</div>
        </div>
        <div className="data-container">
          <div className="question">{t("verifyReviewAuthenticity")}</div>
          <div className="answer">{t("verifyReviewAuthenticityAnswer")}</div>
        </div>
        <div className="data-container">
          <div className="question">{t("askForLocation")}</div>
          <div className="answer">{t("askForLocationAnswer")}</div>
        </div>
        <div className="data-container">
          <div className="question">{t("askForPhoneNumber")}</div>
          <div className="answer">{t("askForPhoneNumberAnswer")}</div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default FAQs;
