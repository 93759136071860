import React, { useCallback, useContext, useEffect, useState } from "react";
import "./style.css";
import Logo from "../../../Assets/Images/betterLady.png";
import { Button, Dropdown } from "antd";
import Left from "../../../Assets/Icons/left.svg";
import Right from "../../../Assets/Icons/right.svg";
import {
  BellFilled,
  CompassOutlined,
  LogoutOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import ShopsList from "./ShopsList";
import Pin from "../../../Assets/Icons/pin.svg";
import Check from "../../../Assets/Icons/check.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Footer from "../../../Components/Common/Footer";
import { UserContext } from "../../../App";
import {
  handleLogout,
  handleUpdateUser,
} from "../../../Utils/UpdateUsersState";
import { getRecommended, shopsNearby } from "../../../API/shop";
import MainImage from "../../../Assets/Images/background.png";
import ChooseLanguage from "../../../Components/Common/ChooseLanguage";
import ForumButton from "../../../Components/Common/Forum Button";
import Notifications from "../../../Components/Common/Notifications";

const Home = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { userData, setUserData, setUserToken } = useContext(UserContext);
  const [location, setLocation] = useState(null);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation(`${latitude}, ${longitude}`);
        },
        (error) => {
          console.error("Error fetching location:", error);
          setLocation(false);
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, [navigator.geolocation]);

  const items = [
    {
      key: "1",
      label: "Settings",
      icon: <SettingOutlined />,
      onClick: () => {
        handleUpdateUser(setUserData, { ...userData, account: true });
      },
    },
    {
      key: "2",
      label: "Logout",
      icon: <LogoutOutlined />,
      onClick: () => handleLogout(setUserData, setUserToken),
    },
  ];

  const getInitials = useCallback(() => {
    if (userData?.name) {
      return userData.name
        .split(" ")
        .map((word) => word[0].toUpperCase())
        .join("");
    }
  }, [userData?.name]);

  useEffect(() => {
    getInitials();
  }, [getInitials]);

  return (
    <div className="home">
      <ForumButton type={"forum"} />
      <div className="header">
        <img
          src={Logo}
          alt="BetterLady"
          width={"100px"}
          onClick={() => navigate("/")}
        />
        <div className="user-info-language">
          <ChooseLanguage />
          {userData?.username ? (
            <div className="user-info-container">
              <Notifications />

              <Dropdown
                menu={{
                  items,
                }}
                placement="bottomRight"
              >
                {userData?.profile_photo ? (
                  <div className="profile-photo">
                    <img
                      src={userData?.profile_photo}
                      alt={userData?.username}
                    />
                  </div>
                ) : (
                  <div className="user-initials">{getInitials()}</div>
                )}
              </Dropdown>
            </div>
          ) : (
            <div className="header-buttons">
              <Button className="brown" onClick={() => navigate("/signup")}>
                {t("signup")}
              </Button>
              <Button className="yellow" onClick={() => navigate("/login")}>
                {t("login")}
              </Button>
            </div>
          )}
        </div>
      </div>
      <hr />
      <div className="container">
        <div>
          <img src={MainImage} alt="main" className="main-image" />
        </div>
        <div className="options">
          <img src={Right} alt="right" />
          <div className="button-container">
            <Button
              className="purple"
              icon={<CompassOutlined />}
              onClick={() => navigate("/discover")}
            >
              {t("discoverBeautyShops")}
            </Button>
            <Button
              className="purple"
              onClick={() => navigate("/signup", { state: "shop" })}
            >
              {t("registerBeautyShop")}
            </Button>
          </div>
          <img src={Left} alt="left" />
        </div>
        <div>
          <ShopsList
            title={t("shopNearby")}
            icon={Pin}
            url={() => shopsNearby(location)}
            location={location}
          />
          <ShopsList
            title={t("highlyRecommended")}
            icon={Check}
            url={getRecommended}
          />
        </div>
        {!userData?.username && (
          <div className="bottom-tab">
            <div className="bottom-tab-message">
              <BellFilled style={{ fontSize: "48px" }} />
              <p>{t("signupBellMessage")}</p>
            </div>
            <Button className="brown" onClick={() => navigate("/signup")}>
              {t("signup")}
            </Button>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Home;
