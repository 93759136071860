import React, { useState } from "react";
import "./style.css";
import Logo from "../../../../Assets/Images/betterLady.png";
import { Button, Form, Input, notification } from "antd";
import { useNavigate } from "react-router-dom";
import LanguageSelector from "../../../../Components/Common/LanguageSelector";
import { useTranslation } from "react-i18next";
import { forgot } from "../../../../API/auth";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    const res = await forgot(values);
    if (Math.floor(res?.status / 100) === 2) {
      api["success"]({
        message: t("emailSent"),
        description: t("checkInbox"),
      });
      
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } else {
      api["error"]({
        message: t("invalidUser"),
        description: res?.response?.data?.detail,
      });
    }
    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <>
      {contextHolder}
      <div className="signin">
        <LanguageSelector />
        <div className="container">
          <img
            src={Logo}
            alt="better-lady"
            width={"100px"}
            onClick={() => navigate("/")}
          />
          <p className="heading">{t("please-enter-email")}</p>
          <Form
            style={{
              width: 350,
              marginTop: "40px",
            }}
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label={t("email")}
              name="email"
              style={{ marginBottom: "5px" }}
              rules={[
                {
                  required: true,
                  message: t("emailWarning"),
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item>
              <div className="message-container">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="submit-button"
                  disabled={loading}
                  loading={loading}
                >
                  {t("verify-email")}
                </Button>
              </div>
              <div className="message-container">
                <Button className="back-button" onClick={() => navigate(-1)}>
                  {t("go-back")}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
