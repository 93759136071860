import {
  ArrowLeftOutlined,
  LogoutOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Button, Dropdown } from "antd";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../App";
import {
  handleLogout,
  handleUpdateUser,
} from "../../../Utils/UpdateUsersState";
import ChooseLanguage from "../../Common/ChooseLanguage";
import Notifications from "../../Common/Notifications";
import "./style.css";

const Navbar = ({ title = null, buttons = true }) => {
  const navigate = useNavigate();
  const { userData, setUserData, setUserToken } = useContext(UserContext);
  const { t } = useTranslation();

  const items = [
    !userData?.account && {
      key: "1",
      label: "Settings",
      icon: <SettingOutlined />,
      onClick: () => {
        handleUpdateUser(setUserData, { ...userData, account: true });
      },
    },
    {
      key: "2",
      label: "Logout",
      icon: <LogoutOutlined />,
      onClick: () => handleLogout(setUserData, setUserToken),
    },
  ];

  function getInitials() {
    return userData?.name?.slice(0, 1).toUpperCase() || "S";
  }

  return (
    <div className="navbar">
      <div className="back-container">
        <ArrowLeftOutlined
          onClick={() => {
            navigate(-1);
            handleUpdateUser(setUserData, { ...userData, account: false });
          }}
        />
        <p>{title || t("discover")}</p>
      </div>
      <div className="user-info-language">
        <ChooseLanguage />

        {userData?.username ? (
          <div className="user-info-container">
            <Notifications />

            <Dropdown
              menu={{
                items,
              }}
              placement="bottomRight"
            >
              {userData?.profile_photo ? (
                <div className="profile-photo">
                  <img src={userData?.profile_photo} alt={userData?.username} />
                </div>
              ) : (
                <div className="user-initials">{getInitials()}</div>
              )}
            </Dropdown>
          </div>
        ) : (
          buttons && (
            <div className="header-buttons">
              <Button className="brown" onClick={() => navigate("/signup")}>
                {t("signup")}
              </Button>
              <Button className="yellow" onClick={() => navigate("/login")}>
                {t("login")}
              </Button>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default Navbar;
