import { LogoutOutlined, SettingOutlined } from "@ant-design/icons";
import { Button, Dropdown, QRCode } from "antd";
import React, { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Logo from "../../../Assets/Images/betterLady.png";
import { UserContext } from "../../../App";
import ChooseLanguage from "../../../Components/Common/ChooseLanguage";
import {
  handleLogout,
  handleUpdateUser,
} from "../../../Utils/UpdateUsersState";
import "./style.css";
import Footer from "../../../Components/Common/Footer";
import { IoLogoInstagram } from "react-icons/io5";
import { MdWhatsapp } from "react-icons/md";
import { HiOutlineMail } from "react-icons/hi";
import {
  handleEmail,
  handleInstagram,
  handleWhatsapp,
} from "../../../Utils/Helper/socialActions";
import Notifications from "../../../Components/Common/Notifications";

const ContacttUs = () => {
  const { userData, setUserData, setUserToken } = useContext(UserContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const items = [
    {
      key: "1",
      label: "Settings",
      icon: <SettingOutlined />,
      onClick: () => {
        handleUpdateUser(setUserData, { ...userData, account: true });
      },
    },
    {
      key: "2",
      label: "Logout",
      icon: <LogoutOutlined />,
      onClick: () => handleLogout(setUserData, setUserToken),
    },
  ];

  const getInitials = useCallback(() => {
    if (userData?.name) {
      return userData.name
        .split(" ")
        .map((word) => word[0].toUpperCase())
        .join("");
    }
  }, [userData?.name]);

  useEffect(() => {
    getInitials();
  }, [getInitials]);

  return (
    <div className="contact-us">
      <div className="header">
        <img
          src={Logo}
          alt="BetterLady"
          width={"100px"}
          onClick={() => navigate("/")}
        />
        <div className="user-info-language">
          <ChooseLanguage />
          {userData?.username ? (
            <div className="user-info-container">
              <Notifications />

              <Dropdown
                menu={{
                  items,
                }}
                placement="bottomRight"
              >
                {userData?.profile_photo ? (
                  <div className="profile-photo">
                    <img
                      src={userData?.profile_photo}
                      alt={userData?.username}
                    />
                  </div>
                ) : (
                  <div className="user-initials">{getInitials()}</div>
                )}
              </Dropdown>
            </div>
          ) : (
            <div className="header-buttons">
              <Button className="brown" onClick={() => navigate("/signup")}>
                {t("signup")}
              </Button>
              <Button className="yellow" onClick={() => navigate("/login")}>
                {t("login")}
              </Button>
            </div>
          )}
        </div>
      </div>
      <hr />
      <div className="container">
        <div className="heading">
          <p>{t("contactUs")}</p>
        </div>
        <div className="intro">
          <p>{t("contactUsIntro")}</p>
        </div>
        <div className="socials">
          <div
            className="social-container"
            onClick={() => handleWhatsapp("85290750945")}
          >
            <QRCode value="https://wa.me/85290750945" />
            <div className="social-name">
              <MdWhatsapp color="green" className="social-icon" />
              <span>Whatsapp</span>
            </div>
          </div>
          <div
            className="social-container"
            onClick={() =>
              handleInstagram("https://www.instagram.com/betterladyhk/")
            }
          >
            <QRCode value="https://www.instagram.com/betterladyhk/" />
            <div className="social-name">
              <IoLogoInstagram color="purple" className="social-icon" />{" "}
              <span>Instagram</span>
            </div>
          </div>
          <div
            className="social-container"
            onClick={() => handleEmail("support@better-lady.com")}
          >
            <QRCode value="mailto:support@better-lady.com" />
            <div className="social-name">
              <HiOutlineMail color="maroon" className="social-icon" />{" "}
              <span>Email</span>
            </div>
          </div>
        </div>
        <div className="guideline">
          <p>{t("contactUsGuideline")}</p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContacttUs;
